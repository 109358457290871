"use client";

import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import { Transition } from "@headlessui/react";

interface FadeInContainerProps extends PropsWithChildren {
  className?: string;
  eager?: boolean;
  show?: boolean;
  onViewportEnter?: () => void;
}

export const FadeInContainer: FC<FadeInContainerProps> = ({
  eager = false,
  show: parentShow = true,
  className,
  onViewportEnter,
  ...props
}) => {
  // Fade-in just once, not everytime it enters the view
  const [show, setShow] = useState(eager);

  const { ref, inView } = useInView();

  useEffect(
    () => {
      if (!show && inView) {
        onViewportEnter?.();
        setTimeout(() => setShow(true), 100);
      }
    },
    // @TODO: PLEASE FIX: update hook deps accordingly when touching this file
    // Ref: https://react.dev/learn/removing-effect-dependencies#dependencies-should-match-the-code
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inView],
  );

  return (
    <div ref={ref} className={className}>
      <Transition
        appear
        show={parentShow || show}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        {...props}
      />
    </div>
  );
};
